export const homeObjTwo = {
    id: 'ourservices',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine1: 'Why us?',
    headline1: 'What makes people place their trust in us?',
    description1: 'Our reputation as a trusted partner is built upon a solid foundation of consistently surpassing client expectations, fueled by the expertise and passion of our team. Moreover, our dedication to providing cost-effective solutions further solidifies the trust our clients place in us, knowing that we not only deliver exceptional results but also optimize their investment.',
    description2: '',
    description3: 'We are able to create an effective digital strategy by using only the latest technology and coding languages to make sure that our processes are up to date. Moreover, we have an extensive library of content that can be quickly and easily integrated. Plus, our customer support team is available 24/7, to assist you with any queries or concerns you may have.',
    description4: 'It’s why we do what we do, and why we invite you to join us in the journey to a healthier and brighter future.',
    description5: "Perago provides innovative IT solutions for businesses to thrive online. From Website Development, Digital Marketing to Admin Dashboards, our expert team delivers customized solutions that drive growth and maximize efficiency",
    buttonLabel: 'Learn More',
    imgStart: false,
    img: require('../../images/svg-1.svg'),
    alt: 'Expert',
    dark: true,
    primary: true,
    darkText: true,
};