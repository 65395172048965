import React from 'react'
import {
    FooterContainer,
    FooterWrap,
    FooterLinksContainer,
    FooterLinksWrapper,
    FooterLinkItems,
    CountryContainer,
    FlagIcon,
    FooterLinkTitle1,
    FooterLinkTitle,
    FooterLink,
    AddressLine,
    SocialMedia,
    SocialMediaWrap,
    SocialLogo,
    VerifaviaCheckLogo,
    WebsiteRights,
    SocialIcons,
    SocialIconLink1,
    SocialIconLink2,
    SocialIconLink3
} from './FooterElements'
import { FaFacebook } from 'react-icons/fa'
import { FaTwitter } from 'react-icons/fa';
import { FaLinkedin } from 'react-icons/fa';
import { animateScroll as scroll } from 'react-scroll'
import IndianFlag from '../../images/flag-1.png';
import FranceFlag from '../../images/flag-2.png';
import SingaporeFlag from '../../images/flag-3.png';
import RequestDemo from '../RequestDemo';
const Foorter = () => {

    const toggleHome = () => {
        scroll.scrollToTop();
    }
    return (
        <FooterContainer id="contactus">
            <FooterWrap>
                <FooterLinksContainer>
                    <FooterLinksWrapper>
                        <FooterLinkItems>
                            <FooterLinkTitle1>Get Connected</FooterLinkTitle1>
                            <AddressLine>Reach out to us today and let's start a meaningful conversation</AddressLine>
                            <AddressLine>about your digital success.</AddressLine>
                            {/* <FooterLink to="https://www.verifavia-shipping.com/"> About Verifavia Shipping </FooterLink>
                            <FooterLink to="https://www.verifavia.com/"> About Verifavia Aviation </FooterLink> */}
                        </FooterLinkItems>
                        <FooterLinkItems>
                            <CountryContainer>
                                <FlagIcon src={IndianFlag} />
                                <FooterLinkTitle> India </FooterLinkTitle>
                            </CountryContainer>
                            <AddressLine> 9486, St.no.1, Joshi Nagar, Haibowal Kalan -141001 </AddressLine>
                            <AddressLine> Ludhiana, Punjab, India </AddressLine>
                            <AddressLine>  </AddressLine>
                            <AddressLine>  </AddressLine>
                            <AddressLine> +91 9878 00 6888 </AddressLine>
                        </FooterLinkItems>
                    </FooterLinksWrapper>
                    <FooterLinksWrapper>
                        <FooterLinkItems>
                            <AddressLine> © Copyright 2023. </AddressLine>
                            <AddressLine> All rights reserved. Powered by Perago </AddressLine>
                        </FooterLinkItems>
                    </FooterLinksWrapper>
                </FooterLinksContainer>

                <SocialMedia>
                    <SocialMediaWrap>
                            <SocialLogo to="/" onClick={toggleHome}>
                             Perago
                            </SocialLogo>
                        <WebsiteRights>peragodigital@gmail.com </WebsiteRights>
                        {/* <SocialIcons>
                            <SocialIconLink1 href="https://twitter.com/VerifaviaMarine" target="_blank" arial_label="Twitter">
                                <FaTwitter />
                            </SocialIconLink1>
                            <SocialIconLink2 href="https://www.facebook.com/verifaviashipping/" target="_blank" arial_label="Facebook">
                                <FaFacebook />
                            </SocialIconLink2>
                            <SocialIconLink3 href="https://www.linkedin.com/company/verifavia-shipping" target="_blank" arial_label="Linkedin">
                                <FaLinkedin />
                            </SocialIconLink3>
                        </SocialIcons> */}
                    </SocialMediaWrap>
                </SocialMedia>

            </FooterWrap>
        </FooterContainer >

    )
}

export default Foorter