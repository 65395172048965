import React, { useState } from 'react';
import Video from '../../videos/video.webm';
import { HeroContainer, HeroBg, VideoBg, HeroContent, HeroP, HeroBtnWrapper, ArrowForward, ArrowRight } from './HeroElements';
import { Button } from '../ButtonElements';
import AnimationComponent from './RollerAnimation/index'
const HeroSection = () => {

  const [hover, setHover] = useState(false)

  const onHover = () => {
    setHover(!hover)
  }

  return (
    <HeroContainer id="home">
      <HeroBg>
        <VideoBg autoPlay loop muted src={Video} type='video/webm' />
      </HeroBg>
      <HeroContent>
        <AnimationComponent />

        <HeroP>
          We help businesses achieve their digital goals with tailored solutions and innovative strategies, let's work together to create a digital growth strategy that's right for you.
        </HeroP>

        <HeroBtnWrapper>
          <Button to="https://forms.gle/SQZrQVnEjCyurSk97" target="_blank" onMouseEnter={onHover}
            onMouseLeave={onHover} primary='true' dark='true'>
            Request a Call {hover ? <ArrowForward /> : <ArrowRight />}
          </Button>
        </HeroBtnWrapper>

      </HeroContent>
    </HeroContainer>
  )
}

export default HeroSection