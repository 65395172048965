import React from 'react';
import { FaBars } from 'react-icons/fa';
import { animateScroll as scroll } from 'react-scroll'
import { Nav, NavbarContainer, NavLogo, MobileIcon, NavMenu, NavItem, NavLinks, NavBtn, NavBtnLink, NavBtnLink1, ImgWrap, Img } from './NavbarElements';
import logo from '../../images/logo.png';

const Navbar = ({ toggle, img, alt }) => {

    const toggleHome = () => {
        scroll.scrollToTop();
    }
    return (
        <>
            <Nav>
                <NavbarContainer>
                    <NavLogo to="/" onClick={toggleHome}>
                        <ImgWrap>
                            <Img src = {logo}/>
                        </ImgWrap>
                    </NavLogo>
                    <MobileIcon onClick={toggle}>
                        <FaBars />
                    </MobileIcon>
                    <NavMenu>
                        <NavItem>
                            <NavLinks to="about"
                                smooth={true} duration={500} spy={true} exact='true' offset={-80}
                            >About</NavLinks>
                        </NavItem>
                        <NavItem>
                            <NavLinks to="ourservices"
                                smooth={true} duration={500} spy={true} exact='true' offset={-80}

                            >Services</NavLinks>
                        </NavItem>
                        {/* <NavItem>
                            <NavLinks to="ourpresence"
                                smooth={true} duration={500} spy={true} exact='true' offset={-80}
                            >Our Presence</NavLinks>
                        </NavItem> */}
                        <NavItem>
                            <NavLinks to="contactus"
                                smooth={true} duration={500} spy={true} exact='true' offset={-280}
                            >Contact Us</NavLinks>
                        </NavItem>
                    </NavMenu>
                    <NavBtn>
                        <NavBtnLink1 to="https://forms.gle/SQZrQVnEjCyurSk97" target="_blank">Request a Call</NavBtnLink1>
                        {/* <NavBtnLink to="https://www.supplierapp.verifaviatechnical.com/" target="_blank">Login</NavBtnLink> */}
                    </NavBtn>
                </NavbarContainer>
            </Nav>
        </>
    )
}

export default Navbar