import React from 'react'
import Icon1 from '../../../images/svg-3.svg'
import Icon2 from '../../../images/svg-2.svg'
import Icon3 from '../../../images/svg-5.svg'
import Icon4 from '../../../images/svg-6.svg'
import Icon5 from '../../../images/svg-7.svg'
import Icon6 from '../../../images/svg-12.svg'
import useTypingEffect from '../TypingEffect/index';
import { useState } from 'react';

import {
    ServicesWrapper,
    ServicesCard,
    ServicesIcon,
    ServicesH2,
    ServicesP
} from './CardSectionElements'

const CardSection = () => {
    const shortDescriptions = [
        '',
        '',
        '',
        '',
        ''
    ];
    const longDescriptions = [
        'Crafted to perfection, our websites combine stunning design with seamless functionality to captivate and engage your online audience.',
        "Elevate your brand's online presence with our strategic approach, leveraging SEO, social media, and compelling content for maximum impact.",
        'Unlock a steady stream of potential clients through our targeted strategies, converting interest into valuable business opportunities.',
        'From inception to launch, we guide you through establishing a robust online presence, ensuring a strong foundation for your digital success.',
        'Harness the power of artificial intelligence to create captivating video advertisements that resonate with your audience and drive meaningful engagement.',
        'Seamlessly manage and optimize your operations with intuitive admin dashboards, providing real-time insights for informed decision-making.'
    ];

    const [expandedDescriptions, setExpandedDescriptions] = useState({
        0: false,
        1: false,
        2: false,
        3: false,
        4: false
    });

    const toggleDescription = (index) => {
        setExpandedDescriptions(prevState => ({
            ...prevState,
            [index]: !prevState[index]
        }));
    };

    const buttonStyles = {
        background: 'none',
        color: 'black',
        border: 'none',
        padding: '8px 16px',
        cursor: 'pointer',
    }

    const numberHighlights = {
        color: 'black',
    }

    



    return (
        <>
            <ServicesWrapper>
                <ServicesCard>
                    <ServicesIcon src={Icon1} />
                    <ServicesH2>Website Development</ServicesH2>
                    <ServicesP>{expandedDescriptions[0] ? longDescriptions[0] : shortDescriptions[0]}<br /><button onClick={() => toggleDescription(0)} style={buttonStyles}>{expandedDescriptions[0] ? 'Read Less' : 'Read More'}</button></ServicesP>
                </ServicesCard>
                <ServicesCard>
                <ServicesIcon src={Icon4} />
                    <ServicesH2>Digital Marketing</ServicesH2>
                    <ServicesP>{expandedDescriptions[1] ? longDescriptions[1] : shortDescriptions[1]}<br /><button onClick={() => toggleDescription(1)} style={buttonStyles}>{expandedDescriptions[1] ? 'Read Less' : 'Read More'}</button></ServicesP>
                </ServicesCard>
                <ServicesCard>
                    <ServicesIcon src={Icon3} />
                    <ServicesH2>Business Lead Generation</ServicesH2>
                    <ServicesP>{expandedDescriptions[2] ? longDescriptions[2] : shortDescriptions[2]}<br /><button onClick={() => toggleDescription(2)} style={buttonStyles}>{expandedDescriptions[2] ? 'Read Less' : 'Read More'}</button></ServicesP>
                </ServicesCard>
                <ServicesCard>
                    <ServicesIcon src={Icon2} />
                    <ServicesH2>Online Business Setup</ServicesH2>
                    <ServicesP>{expandedDescriptions[3] ? longDescriptions[3] : shortDescriptions[3]}<br /><button onClick={() => toggleDescription(3)} style={buttonStyles}>{expandedDescriptions[3] ? 'Read Less' : 'Read More'}</button></ServicesP>
                </ServicesCard>
                <ServicesCard>
                <ServicesIcon src={Icon6} />
                    <ServicesH2> AI Based Video Ads</ServicesH2>
                    <ServicesP>{expandedDescriptions[4] ? longDescriptions[4] : shortDescriptions[4]}<br /><button onClick={() => toggleDescription(4)} style={buttonStyles}>{expandedDescriptions[4] ? 'Read Less' : 'Read More'}</button></ServicesP>
                </ServicesCard>
                <ServicesCard>
                    <ServicesIcon src={Icon5} />
                    <ServicesH2>Admin Dashboard</ServicesH2>
                    <ServicesP>{expandedDescriptions[5] ? longDescriptions[5] : shortDescriptions[5]}<br /><button onClick={() => toggleDescription(5)} style={buttonStyles}>{expandedDescriptions[5] ? 'Read Less' : 'Read More'}</button></ServicesP>
                </ServicesCard>
            </ServicesWrapper>
        </>
    )
}

export default CardSection
